body {
  margin: 0;
  background: linear-gradient(0deg, #313F40, #313F40), #354546;
  font-family: 'Poppins';
  font-weight: 400;
  color: #5A7679;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #313F40; 
  width: 5px;
  height: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5A7679;
  border-radius: 12px;
  width: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #354A50;
}

/* override calendar styles */

.secondary .MuiPickersDay-daySelected {
  background-color: rgba(226, 96, 96, 0.34);
}

.circle {
  height:60px;
  width:60px;
  border-radius:50%;
  background-color:transparent;
  position:relative;
  top: 30px;
  left: 30px;
  -webkit-transition:height .25s ease, width .25s ease;
  transition:height .25s ease, width .25s ease;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
}

.circle:hover{
  height:60px;
  width:60px;
}

.circle:before,
.circle:after {
  content:'';
  display:block;
  position:absolute;
  top:0; right:0; bottom:0; left:0;
  border-radius:50%;
  border:1px solid rgba(166, 55, 55, 0.3);
}

.circle:before {
  -webkit-animation: ripple 2s linear infinite;
  animation: ripple 2s linear infinite;
}
.circle:after {
  -webkit-animation: ripple 2s linear 1s infinite;
  animation: ripple 2s linear 1s infinite;
}

.circle:hover:before,
.circle:hover:after {
  -webkit-animation: none;
  animation: none;
  border: none;
}

@-webkit-keyframes ripple{
  0% {-webkit-transform:scale(1); }
  75% {-webkit-transform:scale(1.5); opacity:0.5;}
  100% {-webkit-transform:scale(1.75); opacity:0;}
}

@keyframes ripple{
  0% {transform:scale(1); }
  75% {transform:scale(1.5); opacity:0.5;}
  100% {transform:scale(1.75); opacity:0;}
}

canvas {
  background: linear-gradient(0deg, rgba(27, 126, 126, 0.6) 7.86%, rgba(37, 89, 96, 0) 90.08%);
}

canvas#statistics {
  border-radius: 10px;
}

.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: #273536 !important;
}

